
import { defineComponent } from 'vue';
import Info from '@/components/sections/Info.vue';
import Notice from '@/components/sections/Notice.vue';

export default defineComponent({
  name: 'verify-account-notice',
  props: {
    sending: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Info,
  },
});
