import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-60 mx-auto p-3 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerifyAccountNotice = _resolveComponent("VerifyAccountNotice", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VerifyAccountNotice, {
      sending: _ctx.sending,
      onClick: _ctx.handleClick
    }, null, 8, ["sending", "onClick"])
  ]))
}